import { Component } from "react";
import { Notation } from "react-abc";

class AbcPreview extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  getAbcPreview() {
    let abc = "";
    let done = false;
    this.props.abc.split("\n").forEach((row) => {
      if (done) {
      } else if (
        row.substr(0, 2) === "K:" ||
        row.substr(0, 2) === "M:" ||
        row.substr(0, 2) === "L:"
      ) {
        abc += row + "\n";
      } else if (
        (row.substr(1, 1) === ":" && row.substr(0, 2) !== "|:") ||
        row.substr(0, 1) === "%" ||
        row === ""
      ) {
      } else {
        abc += row + "\n";
        done = true;
      }
    });
    return abc;
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    const { item, onClick, id } = this.props;
    return (
      <div
        onClick={() => onClick(item)}
        data-id={id}
        style={{ marginBottom: "0.2rem" }}
      >
        {this.state.hasError ? (
          ""
        ) : (
          <Notation
            notation={
              this.props.abcPreview
                ? this.props.abcPreview
                : this.getAbcPreview()
            }
            engraverParams={{
              responsive: "resize",
              add_classes: true,
            }}
          />
        )}
      </div>
    );
  }
}

export default AbcPreview;
