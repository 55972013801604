import { Component } from "react";

class SearchItemDetails extends Component {
  render() {
    const { item } = this.props;
    return (
      <div style={{ color: "#aaa" }}>
        {item.type ? " - " + item.type : item.rhythm ? " - " + item.rhythm : ""}
        {item.province ? " - " + item.province : ""}
        {item.origin ? " - " + item.origin : ""}
        {item.country ? " - " + item.country : ""}
        {item.book ? " - " + item.book : ""}
      </div>
    );
  }
}
export default SearchItemDetails;
