import { Component } from "react";
import AbcPreview from "./AbcPreview";
import SearchItemDetails from "./SearchItemDetails";

class SearchItem extends Component {
  render() {
    const { item, onClick, preview, pattern, showDetails } = this.props;
    return (
      <div
        onClick={() => onClick(item)}
        data-id={item.id}
        style={{ marginBottom: "0.2rem" }}
      >
        {item.title ? item.title : "(inget namn)"}
        {item.composer
          ? " (" + item.composer + ")"
          : item.source
          ? " (" + item.source + ")"
          : ""}
        <span style={{ color: "#aaa" }}>
          {" "}
          {item.importArchive == "sls" ? "sls.fi" : "folkwiki.se"}{" "}
        </span>
        [
        {item.levenshtein !== undefined && pattern
          ? Math.round(
              ((pattern.length - item.levenshtein) * 100) / pattern.length
            ) + "%"
          : ""}
        ]{showDetails ? <SearchItemDetails item={item} /> : ""}
        {preview ? (
          <AbcPreview
            onClick={() => onClick(item)}
            id={item.id}
            abc={item.abc}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default SearchItem;
