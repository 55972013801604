import { Button } from "@material-ui/core";
import { Component } from "react";
import { Notation } from "react-abc";
import Search from "./Search";

class Tune extends Component {
  constructor(props) {
    super(props);
    this.state = { searchSimilar: false };
  }
  searchSimilar(tuneId) {
    /*this.props.searchSimilar(tuneId);
    this.props.onClose();*/
    this.setState({ searchSimilar: true });
  }

  render() {
    const { tune } = this.props;
    let link = "";
    if (tune.importArchive == "folkwiki.se" && tune.file)
      link = (
        <a
          style={{ color: "#00f" }}
          target="_new"
          href={"http://www.folkwiki.se/Musik/" + tune.file.replace(".abc", "")}
        >
          Se låten på folkwiki.se
        </a>
      );
    const searchSimilar = this.state.searchSimilar ? (
      <div>
        <h2>Låtar som börjar likadant</h2>
        <p>
          Denna funktion är inte så mycket utvecklad, endast på låtar som har en
          liknande början.
        </p>
        <Search id={tune.id} mode="resultsOnly"></Search>
      </div>
    ) : (
      ""
    );

    return (
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={this.props.onClose}
        >
          Tillbaka
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => this.searchSimilar()}
        >
          Sök liknande
        </Button>
        <h2 style={{ textAlign: "center" }}>{tune.title}</h2>
        <div style={{ textAlign: "right" }}>
          <div>{tune.composer ? tune.composer : ""}</div>
          <div>
            {tune.origin ? tune.origin : tune.province ? tune.province : ""}
          </div>
          <div>
            {tune.transcription
              ? "Uppteckning: " + tune.transcription
              : tune.transcription}
          </div>
        </div>
        <Notation
          notation={tune.abc}
          engraverParams={{
            responsive: "resize",
          }}
        />
        <div style={{ textAlign: "center" }}>{link}</div>

        <div>
          {tune.rhythm
            ? "Låttyp: " + tune.rhythm
            : tune.type
            ? "Låttyp: " + tune.type
            : ""}
        </div>
        <div>{tune.book ? "Bok/uppteckning: " + tune.book : ""}</div>
        <div>{tune.source ? "Källa: " + tune.source : ""}</div>
        <div>{tune.history ? "Historik: " + tune.history : ""}</div>
        <div>{tune.note ? "Anteckning: " + tune.note : ""}</div>
        {searchSimilar}
      </div>
    );
  }
}

export default Tune;
